@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	img,
	svg,
	video,
	canvas,
	audio,
	iframe,
	embed,
	object {
		display: inline-block;
	}
}

html,
body,
#root {
	height: 100vh;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.person-actions {
	width: max-content;
}
.flex-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

input[type="file"] {
	border: 1px solid rgba(0, 0, 0, 0.23);
	padding: 0.5rem;
	width: 100%;
	border-radius: 0.4em;
}
input[type="file"]::file-selector-button {
	border: 2px solid #ec1c24;
	padding: 0.6em;
	border-radius: 0.4em;
	background-color: #fff;
	transition: 0.4s;
}

input[type="file"]::file-selector-button:hover {
	background-color: #ec1c24;
	border: 2px solid #ec1c24;
	color: white;
}

.MuiLink-root {
	cursor: pointer;
}

@media (max-width: 560px) {
	.w-sm-full {
		width: 100%;
	}
}

.search-field .MuiOutlinedInput-adornedEnd {
	padding-right: 0;
}

.search-field .MuiSelect-filled.MuiSelect-filled {
	padding-top: 10px;
}

.select-filter .MuiSelect-outlined.MuiSelect-outlined {
	padding: 10px 32px 10px 14px;
}
